// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-cam-service-for-a-film-or-series-js": () => import("./../../../src/pages/contact-us/cam-service-for-a-film-or-series.js" /* webpackChunkName: "component---src-pages-contact-us-cam-service-for-a-film-or-series-js" */),
  "component---src-pages-contact-us-other-js": () => import("./../../../src/pages/contact-us/other.js" /* webpackChunkName: "component---src-pages-contact-us-other-js" */),
  "component---src-pages-contact-us-reporting-software-js": () => import("./../../../src/pages/contact-us/reporting-software.js" /* webpackChunkName: "component---src-pages-contact-us-reporting-software-js" */),
  "component---src-pages-contact-us-royalty-management-software-js": () => import("./../../../src/pages/contact-us/royalty-management-software.js" /* webpackChunkName: "component---src-pages-contact-us-royalty-management-software-js" */),
  "component---src-pages-knowledge-hub-js": () => import("./../../../src/pages/knowledge-hub.js" /* webpackChunkName: "component---src-pages-knowledge-hub-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-templates-contactus-page-js": () => import("./../../../src/templates/contactus-page.js" /* webpackChunkName: "component---src-templates-contactus-page-js" */),
  "component---src-templates-faqs-page-js": () => import("./../../../src/templates/faqs-page.js" /* webpackChunkName: "component---src-templates-faqs-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-message-page-js": () => import("./../../../src/templates/message-page.js" /* webpackChunkName: "component---src-templates-message-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-usage-rules-page-js": () => import("./../../../src/templates/usage-rules-page.js" /* webpackChunkName: "component---src-templates-usage-rules-page-js" */)
}

